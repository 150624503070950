
import { computed, defineComponent, ref, watch } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: { TmButton },
  emits: ['close-custom-filter', 'update:modelValue'],
  setup(props, context) {
    const { isSmMax } = useBreakpoints()
    const from = ref(new Date('2022-07-08T17:58:58.860Z'))
    const to = ref(new Date('2022-07-15T17:58:58.860Z'))

    const dateRange = computed(() => [from.value, to.value])

    watch(
      () => dateRange.value,
      () => { context.emit('update:modelValue', dateRange.value) },
      { immediate: true }
    )

    return {
      from,
      to,
      isSmMax,
    }
  },
})
