import { useModals } from '@/compositions/modals'

type CallTypes = 'forwarded' | 'outbound' | 'inbound'
type UseHistoryCalls = () => {
  openDeleteModal: (count: number, type: CallTypes) => void
}

const useHistoryCalls: UseHistoryCalls = () => {
  const { openModal } = useModals()

  const openDeleteModal = (count = 1, type = 'forwarded') => {
    openModal('confirmation', {
      title: `Delete ${type} call${count > 1 ? 's' : ''}`,
      text: `Are you sure you would like to delete selected ${type} call${count > 1 ? 's' : ''}? This action cannot be undone.`,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  return {
    openDeleteModal,
  }
}

export default useHistoryCalls
