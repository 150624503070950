import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "light--text ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_outbound_calls_dropdown = _resolveComponent("outbound-calls-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection,
    class: "contacts-table"
  }, {
    "body-cell-icon-slot": _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: "tmi-phone-forwarded",
        size: "large",
        class: "primary--text"
      })
    ]),
    "body-cell-from-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        to: { name: 'base.history.outboundCalls.details' },
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.from.phone) + " ", 1),
          _createElementVNode("span", _hoisted_1, " (" + _toDisplayString(props.row.from.team) + ") ", 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-to-slot": _withCtx((props) => [
      _createVNode(_component_router_link, { to: { name: 'base.history.outboundCalls.details' } }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: props.row.to.name || props.row.to.phone,
            "avatar-color": props.row.to.avatarColor,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-color"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_outbound_calls_dropdown)
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}