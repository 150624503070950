
import { computed, defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { DateFilterValueType } from '@/definitions/_general/_types/dateFilterVariant'
import { dateFilterLabelsMap, dateFilterVariant } from '@/definitions/_general/_data/dateFilterVariant'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import type { PropType } from '@vue/runtime-core'
import { useBreakpoints } from '@/compositions/breakpoints'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmButton,
    TmDropdown,
  },
  props: {
    modelValue: {
      type: String as PropType<DateFilterValueType>,
      default: 'all',
    },
    iconOnly: {
      type: Boolean,
    },
    customDate: {
      type: Array as PropType<string[]>,
    },
    size: {
      type: String as PropType<'' | 'large'>,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const { isSmMax } = useBreakpoints()
    const chooseValue = (val: DateFilterValueType) => {
      if (val !== props.modelValue) {
        context.emit('update:modelValue', val)
      }
    }
    const dateLabel = computed(() => {
      if (props.modelValue === 'custom' && props.customDate?.length) {
        return `${formatDate(props.customDate[0], 'D MMM YY')} - ${formatDate(props.customDate[1], 'D MMM YY')}`
      }
      return dateFilterLabelsMap[props.modelValue]
    })

    return {
      dateFilterVariant,
      isSmMax,
      chooseValue,
      dateLabel,
    }
  },
})
