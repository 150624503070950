import type { DateFilterValueType } from '@/definitions/_general/_types/dateFilterVariant'

export const dateFilterValueVariant = [
  'all',
  'today',
  'yesterday',
  'thisWeek',
  'lastWeek',
  'thisMonth',
  'prevMonth',
  'last60Days',
  'custom',
]

export const dateFilterLabelsMap: Record<DateFilterValueType, string> = {
  all: 'All time',
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'This week',
  lastWeek: 'Last week',
  thisMonth: 'This month',
  prevMonth: 'Previous month',
  last60Days: 'Last 60 days',
  custom: 'Custom',
}

export type DateFilterType = {
  value: DateFilterValueType;
  label: string;
}

export const dateFilterVariant: DateFilterType[] = dateFilterValueVariant.map((item) => ({
  value: item,
  label: dateFilterLabelsMap[item],
}))
