
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ForwardCallsEmpty from '@/components/pages/history/forwardCalls/ForwardCallsEmpty.vue'
import CallsFilter from '@/components/pages/history/CallsFilter.vue'
import OutboundCallsTable from '@/components/pages/history/outboundCalls/OutboundCallsTable.vue'
import { getTableData } from '@/services/tableService'
import { useModes } from '@/compositions/modes'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { OutboundCallType } from '@/definitions/history/outboundCalls/types'
import useHistoryCalls from '@/compositions/history/useHistoryCalls'

export default defineComponent({
  components: {
    CallsFilter,
    OutboundCallsTable,
    ForwardCallsEmpty,
    PageContent,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openDeleteModal } = useHistoryCalls()

    const search = ref('')
    const selected = ref<OutboundCallType[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: '', value: 'icon-slot', width: '40px' },
      { text: 'From', value: 'from-slot' },
      { text: 'To', value: 'to-slot' },
      { text: 'Call length', value: 'callLength' },
      { text: 'Call time', value: 'callTime', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref<OutboundCallType[]>(getTableData('outboundCalls'))
    const filteredTableItems = computed<OutboundCallType[]>(() => {
      return tableItems.value.filter((item) => {
        if (item.to.name) {
          return item.to.name.toLowerCase().includes(search.value.toLowerCase())
        }
        return item.to.phone.toLowerCase().includes(search.value.toLowerCase())
      })
    })

    return {
      openDeleteModal,
      search,
      selected,
      tableHeaders,
      tableItems,
      isEmptyMode,
      filteredTableItems,
    }
  },
})
